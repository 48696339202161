import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../../components/LayoutDAI'

import Hero from '../../../components/dai/Hero'
import Section from '../../../components/dai/Section'
import IconCard from '../../../components/dai/IconCard'
import Quote from '../../../components/dai/Quote'
import { Heading1 } from '../../../components/dai/Heading1'
import { Heading2 } from '../../../components/dai/Heading2'
import { Text } from '../../../components/dai/Text'
import Columns from '../../../components/dai/Columns'
import Column from '../../../components/dai/Column'
import PageNavigation from '../../../components/dai/PageNavigation'
import { BoldText } from '../../../components/dai/BoldText'
import { Button } from '../../../components/dai/Button'

import SEO from '../../../components/SEO'
import Space from '../../../components/common/Space'
import TextLink from '../../../components/common/TextLink'

import Keyboard from '../../../images/dai/icons/assets/keyboard.svg'
import AppendClip from '../../../images/dai/icons/assets/append-clip.svg'
import VideoPlaylist from '../../../images/dai/icons/assets/video-playlist.svg'

const MaxImage = styled.div`
  max-width: 200px;
`

export default function Media() {
  return (
    <LayoutDAI
      freeAudit
      showIndustryReports
      pagePath={{
        parent: 'industry-reports',
        child: 'media',
        freeAuditHeading: 'Find out how your site compares to other media sites.',
      }}
    >
      <SEO
        title="Media Accessibility | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/industry-reports/media/"
        desc="Our expert review of top media sites found another 17 accessibility barriers across the pages tested, which included individual articles and contact pages."
        bannerUrl="https://images.prismic.io/audioeye-web/1a3bd0a3-7dc7-4d72-b71e-a8a255f8e20f_media.jpg?auto=compress,format"
        imageAlt="Stylized billboard for the Media industry with a subtitle that reads “No Captioning Available”"
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/industry-reports/media/`}
      />
      <Hero
        image={
          <StaticImage
            src="../../../images/dai/hero/media.png"
            alt="Stylized billboard for the Media industry with a subtitle that reads “No Captioning Available”"
          />
        }
        backgroundColor="orange200"
        noPaddingBottomMobile
      >
        <Text largeCapsInter color="orange900">
          MEDIA
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt aria-label="Media">
          Digital accessibility is making the wrong headlines.
        </Heading1>
        <Space height={32} />
        <Text>
          Across all industries, media sites had some of the highest page failure rates for a number of key
          accessibility barriers, including image accessibility and page navigation.
        </Text>
        <Space height={32} />
        <Text>
          For example, page titles help screen reader users quickly determine what information is on a page, and whether
          they want to keep reading. Yet nearly 62% of pages on media sites have missing frame titles —{' '}
          <BoldText>a rate that is 20% higher than the overall average.</BoldText>
        </Text>
        <Space height={32} />
        <Text>
          In fact, one member of the disability community said that her local paper’s website is so inaccessible, she
          has to get her news from the local Facebook group. Which, as she described it, is “insane.”
        </Text>
      </Hero>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“If I really want to read an article, it’s like ‘Okay, do I need to download an app? Can I read it on the website? How interested am I really?’”"
          author="Lia Stone"
          title="AudioEye A11iance Member and QA Tester"
        />
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          EXPERT AUDITS
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          Breaking down the most significant barriers on top media sites.
        </Heading2>
        <Space height={32} />
        <Text>
          After reviewing the top media sites, our expert testers noted that accessibility barriers made it hard for
          non-sighted users to navigate between articles or fully engage with multimedia content, like slideshows and
          in-article images.
        </Text>
        <Space height={32} />
        <Text>
          On average, our testers found another{' '}
          <BoldText>
            17 accessibility barriers across the pages tested, which included individual articles and contact pages
          </BoldText>{' '}
          — including three consistent barriers that made it difficult to read articles.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns orderedList>
          <Column size={12} listItem>
            <IconCard noCard>
              <Keyboard />
              <Space height={16} />
              <Text heading3 className="asH4">
                1. Keyboard accessibility issues prevented users from clicking between articles.
              </Text>
              <Space height={16} />
              <Text>
                Our experts noted several instances where multimedia content (such as slideshows) was inaccessible to
                keyboard-only users, who were unable to tab between slides using keyboard commands.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/keyboard.html"
                  target="_blank"
                  rel="noreferrer"
                  text="2.1.1: Keyboard"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Mobility
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <AppendClip />
              <Space height={16} />
              <Text heading3 className="asH4">
                2. Missing skip links made site navigation difficult for screen reader users.
              </Text>
              <Space height={16} />
              <Text>
                Our experts noted many pages were missing a “skip to main” link, which enables screen reader users to
                jump past a bunch of unwanted links (such as primary navigation links) and get to the main content on a
                page.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/bypass-blocks.html"
                  target="_blank"
                  rel="noreferrer"
                  text="2.4.1: Bypass Blocks"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <VideoPlaylist />
              <Space height={16} />
              <Text heading3 className="asH4">
                3. Unlabeled video player controls made it difficult to play/pause/stop/hide video.
              </Text>
              <Space height={16} />
              <Text>
                Our experts encountered several video players that had unlabeled control buttons, meaning screen reader
                users weren’t able to play, pause, or stop video content.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/pause-stop-hide.html"
                  target="_blank"
                  rel="noreferrer"
                  text="2.2.2: Pause, Stop, Hide"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
              </Text>
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“Media newsletters tend to be really inaccessible. It’s like they pay less attention to compliance for things like email.”"
          author="TJ Olsen"
          title="AudioEye A11iance Member and QA Tester"
        />
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          AUTOMATED SCAN RESULTS
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          Most common issues, media sites.
        </Heading2>
        <Space height={32} />
        <Text>
          Our scan revealed a number of significant accessibility issues on media sites, with the most frequent
          offenders being missing page titles and a lack of image alternative text on images.
        </Text>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/triangle-sign-73.png"
                alt="Pie chart representing 73%. The pie chart is in the shape of a yield traffic sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              73% of pages on media sites had at least one image with missing or inadequate alt text.
            </Text>
            <Space height={24} />
            <Text>
              Without descriptive alt text on images and other graphics, people with visual and cognitive impairments
              can struggle to understand what each one is supposed to illustrate.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html"
                target="_blank"
                rel="noreferrer"
                text="1.1.1: Non-Text Content"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/triangle-sign-67.png"
                alt="Pie chart representing 67%. The pie chart is in the shape of a yield traffic sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              67% of pages on media sites had at least one link that did not clearly state where it would take users.
            </Text>
            <Space height={24} />
            <Text>
              People tend to browse media sites by navigating to a specific section, like ‘Health’ or ‘Entertainment’.
              When these sections are not clearly labeled, or when related articles are not clearly labeled, it can be
              difficult for screen reader users to navigate the site.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-in-context.html"
                target="_blank"
                rel="noreferrer"
                text="2.4.4: Link Purpose (In Context)"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" paddingBottom="lg" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/triangle-sign-62.png"
                alt="Pie chart representing 62%. The pie chart is in the shape of a yield traffic sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              62% of pages on media sites were missing frame titles.
            </Text>
            <Space height={24} />
            <Text>
              Media sites had the highest rate of missing frame titles across all industries scanned, which can make it
              difficult for screen reader users to find content or orient themselves within a page.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions.html"
                target="_blank"
                rel="noreferrer"
                text="3.3.2: Labels or Instructions"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="xl" paddingBottom="xl" constraint="lg" backgroundColor="orange300">
        <Columns>
          <Column size={16} tabletSize={14}>
            <Text heading3 className="asH4">
              Become the go-to news source for people with disabilities.
            </Text>
            <Space height={32} />
            <Text>
              Uncover accessibility barriers that are making it hard for people with disabilities to read articles or
              manage their account with an expert audit of your site.
            </Text>
          </Column>
          <Column size={8} tabletSize={10} justifyCenter alignItemsEnd>
            <Button
              text="Schedule Expert Audit"
              to="/digital-accessibility-index/industry-reports/media/#expert-audit"
              size="small"
              tag="DAI Page Content"
            />
          </Column>
        </Columns>
      </Section>
      <PageNavigation
        previousPageLink="/digital-accessibility-index/industry-reports/travel/"
        previousPageText="Travel"
        nextPageLink="/digital-accessibility-index/industry-reports/insurance/"
        nextPageText="Insurance"
      />
    </LayoutDAI>
  )
}
